import React from "react"

// import ForBuyersPage from "./produce-marketplace";
import FeaturesPage from "./features";
import './index.less';

const IndexPage = () => (
  <FeaturesPage />
)

export default IndexPage
